// Dependências
import axios from 'axios'
import { storeToRefs } from 'pinia'
import { useSettingsStore } from '~/stores/settings'

// Composables
import useSessionStorage from '~/composables/useSessionStorage'


export default defineNuxtPlugin((nuxtApp) => {
  // Session storage
  const sessionStorage = useSessionStorage()

  // Pinia
  const settingsStore = useSettingsStore()
  const { simulatorSettings, accountSettings } = storeToRefs(settingsStore)

  // Default axios config
  const config = useRuntimeConfig()
  const axiosInstance = axios.create({
    baseURL: config.public.baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  function configHeaderQueries(onRequest) {
    // Este array deve conter as chaves que serão axexadas no cabeçalho
    const allowHeaderList = {
      utm_source: 'UtmSource',
      utm_campaign: 'UtmCampaign',
      utm_medium: 'UtmMedium',
      utm_term: 'UtmTerm',
      utm_content: 'UtmContent'
    }

    const queryString = sessionStorage.getItem('campaign_utms')

    if (!queryString || !Object.keys(queryString).length) return

    for (const [key, value] of Object.entries(queryString)) {
      if (key in allowHeaderList) {
        onRequest.headers.set(allowHeaderList[key], value)
      }
    }
  }

  function configHeaderToken(onRequest) {
    const bearerToken = simulatorSettings.value.token || accountSettings.value.token

    if (!bearerToken) return

    onRequest.headers.set('Authorization', `Bearer ${bearerToken}`)
  }

  axiosInstance.interceptors.request.use((onRequest) => {
    // Configura cabeçalhos da requisição
    configHeaderQueries(onRequest)
    // Configura token de autenticação
    configHeaderToken(onRequest)
    nuxtApp.$store.dispatch('setLoading', true)
    return onRequest
  })

  axiosInstance.interceptors.response.use((onResponse) => {
    nuxtApp.$store.dispatch('setLoading', false)
    return onResponse
  }, (onError) => {
    nuxtApp.$store.dispatch('setLoading', false)
    return Promise.reject(onError)
  })

  return {
    provide: {
      axios: axiosInstance
    }
  }
})
